.container {
    justify-content: center;
    justify-items: center;
    margin: auto auto;
    font-size: 150%;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
}

.ContractGen{
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 0.5fr);
    padding: 1rem;
}

.Select{
    height: fit-content;
    padding-left: 10px;
    background-color: rgb(24, 24, 24);
    box-shadow: -1rem 1rem 1rem;
    border-radius: 10px;
    padding: 10px;
}
.options{
    display: grid; 
    grid-template-columns: repeat(1,1fr);
}

.Header {
    text-align: center;
}

.Output{
    margin-left: 5rem;
    overflow: auto;
    flex: 4;
    height: 80vh;
    width: 80vh;
    border-radius: 5px;
    font-size: 1rem;
    background-color: rgb(43, 43, 43);
    box-shadow: 1rem 1rem 1rem;
    /* padding: 1rem; */
}

h4{
    font-size: 1rem;
    color: white;
    font-weight: normal;
}

input[type='text']{
    width: 10vw;
    height: 3vh;
    background: #FFFFFF;
    border-radius: 10px;
    margin: 10px 10px 10px;
    padding-left: 10px;
}
input[type='checkbox']{
    width: 2vw;
    height: 2vh;
    margin: 0 0 0;
}

button{
    width: 5vw;
    height: 3vh;
    background-color: grey;
    color: white;
    border: 0;
    margin-right: 5px;
    border-radius: 5px;
}
button:focus{
    background-color: rgb(24, 24, 24);;
    border: 0;
}

.checkbox{
    display: flex;
}

.discord{
    display: block;
    text-align: right;
}

.discord > a{
    padding-right: 10rem;
    color:gray;
    font-size: 7rem;
}


@media screen and (max-width: 600px) {
    h1{
        font-size: 2rem;
    }
    
    .ContractGen {
        grid-template-columns: repeat(1,1fr);
    }
    .Output{
        width: 90%;
        font-size: 0.2rem;
        margin: 5rem 0.1rem 0.1rem;
        box-shadow: 0 0 0;
    }
    .options{
        grid-template-columns: repeat(1, 1fr);
    }
    p{
        font-size: 0.5rem;
    }
    input[type='text']{
        width: 80vw;
        height: 10vh;
        background: #FFFFFF;
        border-radius: 10px;
        margin: 10px 10px 10px;
    }
    input[type='checkbox']{
        width: 8vw;
        height: 8vh;
    }
    .checkbox{
        margin-top: 10px;
        display: flex;
        gap: 10px;
    }
    
    button{
        width: 30vw;
        height: 3vh;
        background-color: grey;
        color: white;
        border: 0;
    }

    .discord > a{
        display: block;
        text-align: center;
        font-size: 3rem;
        padding-right: 0px;

    }

}