body {
  background-color: #202020;
}
p{
  color: white;
  font-size: 1rem;
}

h1{
  color: white;
  font-size: 4rem;
  text-align: center;
}

label{
  color: white;
  font-size: 1rem;
}
